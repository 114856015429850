import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

export default function Game() {
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "KeysQuiz/KeysQuiz.loader.js",
    dataUrl: "KeysQuiz/KeysQuiz.data",
    frameworkUrl: "KeysQuiz/KeysQuiz.framework.js",
    codeUrl: "KeysQuiz/KeysQuiz.wasm",
  });

  return (
    <>
      {!isLoaded && (
        <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{ visibility: isLoaded ? "visible" : "hidden",
                width : 437, 
                height : 700
        }}
      />
    </>
  );
}



  

  